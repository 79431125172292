@import 'typography';
@import 'z-index';

.cdk-overlay-container {
    z-index: $tooltip-zindex; // stay above modal

    div.mat-mdc-tooltip {
        --mdc-plain-tooltip-supporting-text-size: #{$font-small};
        --mdc-plain-tooltip-supporting-text-tracking: normal;
    }
}
